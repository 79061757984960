
// import {CandidateProgressResults} from "./candidate-datasource";
// import {OnlineProctorProgressResults} from "./online-proctor-datasource";
// import {ProgressResults} from "./results-datasource";
// import {Sales} from "./sales-datasource";
// import {Gauge} from "./gauge";


const solarium = {
    // CandidateProgressResults,
    // OnlineProctorProgressResults,
    // ProgressResults,
    // Sales,
    // Gauge,
};

export default solarium;
